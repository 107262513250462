// contact srea style


.rts-contact-area.contact-one{
    position: relative;
    z-index: 1;
    &::after{
        position: absolute;
        content: "";
        background-image: url(../images/contact/shape/01.png);
        right: 10%;
        top: -8%;
        height: 190px;
        width: 190px;
        z-index: -24;
        animation: rotateIt 15s linear infinite;
    }
}

.contact-form-area-one{
    padding: 120px 100px;
    background: #fff;
    box-shadow: 0px 21px 46px #0000000a;
    @media #{$sm-layout} {
        padding: 25px;
    }
}


.contact-form-area-one{
    form{
        margin-top: 45px;
        input{
            height: 55px;
            background: #F7F7F7;
            margin-bottom: 20px;
            border-radius: 15px;
            padding: 0 25px;
            border: 1px solid transparent;
            &:focus{
                border: 1px solid var(--color-primary);
            }
        }
        textarea{
            border-radius: 15px;
            background: #F7F7F7;
            height: 150px;
            padding: 15px 25px;
            border: 1px solid transparent;  
            &:focus{
                border: 1px solid var(--color-primary);
            }
        }
        .name-email{
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media #{$sm-layout} {
                flex-direction: column;
            }
            input{
                width: 48.8%;
                @media #{$sm-layout} {
                    width: 100%;
                    display: block;
                }
            }
        }
    }
    .rts-btn{
        display: block;
        max-width: max-content;
        margin-top: 30px;
    }
}

.contact-form-area-one{
    .pre-title{
        letter-spacing: 0.1em;
    }
}

.contact-map-area-fluid{
    position: relative;
    .contact-map{
        width: 100%;
        height: 580px;
    }
    .location{
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 5;
        transform: translate(-50%, -50%);
        max-width: 100px;
        height: auto;
    }   
}


.rts-contact-fluid{
    box-shadow: 0px 24px 39px rgba(0, 0, 0, 0.05);
    .form-wrapper{
        width: 80%;
        margin: auto;
        @media #{$large-mobile} {
            width: 90%;
        }
    }
}


.rts-contact-fluid{
    .form-wrapper{
        form{
            .name-email{
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media #{$sm-layout} {
                    flex-direction: column;
                }
                input{
                    width: 48.8%;
                    @media #{$sm-layout} {
                        width: 100%;
                    }
                }
            }
        }
        input{
            background: #F6F6F6;
            height: 55px;
            border-radius: 15px;
            margin-bottom: 25px;
            border: 1px solid transparent;
            &:focus{
                border: 1px solid var(--color-primary);
            }
        }
        textarea{
            height: 150px;
            background:#F6F6F6;
            border-radius: 15px;
            padding: 15px 15px;
            border: 1px solid transparent;
            &:focus{
                border: 1px solid var(--color-primary);
            }
        }
    }
    .rts-btn{
        display: block;
        max-width: max-content;
        margin: auto;
        margin-top: 30px;
    }
}


.onepage.two .rts-contact-fluid .form-wrapper{
    width: 100%;
}
.onepage.two{
    .rts-contact-fluid{
        box-shadow: none;
    }
    .rts-title-area p.pre-title{
        font-size: 18px;
        color: #5D666F !important;
        font-weight: 500 !important;
    }
    .rts-title-area.contact-fluid::after {
        content: "09";
        left: 49%;
        transform: translateX(-50%);
        z-index: -1;
        height: 109px;
        top: -40%;
        min-width: max-content;
    }
}

