
.mainmenu{
    li{
        position: relative;
        &.has-droupdown{
            &>a{
                position: relative;
                &::before{
                    content: "\f078" !important;
                    position: absolute !important;
                    top: 51%;
                    font-family:"Font Awesome 5 Pro" !important;
                    right: -18px;
                    transform: translateY(-50%);
                    transition: .3s;
                }
                &:hover{
                    &::before{
                        content: '\f077' !important;
                    }
                }
            }
            .submenu{
                min-width: 230px;
                height: auto;
                position: absolute;
                top: 90%;
                left: 0;
                z-index: 90;
                opacity: 0;
                visibility: hidden;
                text-align: left;
                padding: 12px 0;
                transition: 0.3s;
                border-radius: 0 0 10px 10px;
                background-color: #fff;
                border-left: 1px solid #ffffff14;
                border-bottom: 1px solid #ffffff14;
                border-right: 1px solid #ffffff14;
                border-top: 4px solid var(--color-primary);
                display: inline-block;
                &::after{
                    top: -20px;
                    left: 16%;
                    transform: translateX(-50%);
                    position: absolute;
                    content: "";
                    border: 8px solid transparent;
                    border-bottom-color: var(--color-primary);
                }
                li{
                    margin-right: 0;
                    padding: 0 15px;
                    a{
                        padding: 5px 0;
                        &::after{
                            display: none;
                        }
                    }
                }
            }
        }
        &:hover{
            .submenu{
                opacity: 1;
                visibility: visible;
                top: 100%;
            }
        }
    }
}