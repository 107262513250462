
// start team area

.bg-team{
    background-image: url(../images/team/bg-01.jpg);
    height: 1029px;
    @media #{$laptop-device} {
        height: 978px;
    }
    @media only screen and (max-width: 1499px) {
        height: 931px;
    }
    @media only screen and (max-width: 1399px) {
        height: 879px;
    }
    @media #{$smlg-device} {
        height: auto;
    }
}
.bg-team-color{
    background: #F6F6F6;
}

.bg-white-feature{
    background: #fff;
    box-shadow: 0px 22px 23px rgba(0, 0, 0, 0.07);
}

.team-single-one-start{
    background: #fff;
    border-radius: 8px;
    transition: .3s;
    &:hover{
        transform: translateY(-20px);
    }
    .team-image-area{
        a{
            overflow: hidden;
            display: block;
            position: relative;
            border-radius: 8px 8px 0 0;
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
            img{
                width: 100%;
                transition: .3s;
            }
            // socail area start
            .team-social{
                position: absolute;
                bottom: 30px;
                right: 30px;
                .main{
                    i{
                        padding: 16px;
                        background: #fff;
                        border-radius: 50%;
                        font-size: 16px;
                        line-height: 12px;
                        font-weight: 500;
                        color: var(--color-primary);
                        transition: 0.3s;
                    }
                }
                &:hover{
                    .main{
                        i{
                            background: var(--color-primary);
                            color: #fff;
                        }
                    }
                }
                .team-social-one{
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    transform: translateY(50px);
                    transition: 1s;
                    opacity: 0;
                    i{
                        background: #fff;
                        border-radius: 60%;
                        font-size: 16px;
                        line-height: 23px;
                        font-weight: 500;
                        color: var(--color-primary);
                        transition: 0.3s;
                        margin-bottom: 10px;
                        height: 45px;
                        width: 45px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover{
                            background: var(--color-primary);
                            color: #fff;
                        }
                    }
                }
                &:hover{
                    .main{
                        opacity: 0;
                    }
                    .team-social-one{
                        transform: translateY(-96%);
                        z-index: 2;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .single-details{
        padding: 28px 30px 27px 30px;
        a{
            .title{
                margin-bottom: 0px;
                transition: .3s;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        p{
            color: var(--color-primary);
            font-size: 16px;
        }
    }
}
.team-inner-two{
    position: relative;
    width: 100%;
    a{
        &.thumbnail{
            overflow: hidden;
            display: block;
            border-radius: 15px;
            img{
                width: 100%;
                transition: .3s;
            }
            &:hover{
                img{
                    transform: scale(1.15);
                }
            }
        }
    }
    .acquaintance-area{
        position: absolute;
        left: 0;
        bottom: -20%;
        background: #fff;
        border-radius:0 15px 15px 15px;
        box-shadow: 0px 4px 27px #00000012;
        text-align: center;
        @media #{$md-layout} {
            bottom: 0;
        }
        @media #{$sm-layout} {
            bottom: 0;
        }
        .header{
            padding: 30px 82px 22px 82px;
            border-bottom: 1px solid #F0F0F0;
            @media #{$smlg-device} {
                padding: 20px 62px;
            }
            .title{
                margin-bottom: 5px;
                @media #{$smlg-device} {
                    font-size: 20px;
                }
            }
            span{
                color: var(--color-primary);
                font-weight: 400;
                font-size: 16px;
            }
        }
        .acquaintance-social{
            padding: 27px 84px 36px 84px;
            margin-right: -30px;
            @media #{$smlg-device} {
                padding: 20px 62px;
                margin-right: -30px;
            }
            a{
                position: relative;
                z-index: 1;
                transition: .3s;
                margin-right: 30px;
                i{
                    font-size: 14px;
                    color: #1C2539;
                    transition: .3s;
                }
                &::after{
                    position: absolute;
                    content: '';
                    background: #F6F6F6;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                    transition: .3s;
                }
                &:hover{
                    i{
                        color: #fff;
                    }
                    &::after{
                        background: var(--color-primary);
                    }
                }
            }
        }
    }
}

.home-yellow{
    .team-inner-two .acquaintance-area .header span{
        color: var(--color-primary-3);
    }
    .team-inner-two .acquaintance-area .acquaintance-social a:hover::after{
        background: var(--color-primary-3);
    }
    .progress-wrap svg.progress-circle path{
        stroke: var(--color-primary-3);
    }
    .progress-wrap::after,
    .progress-wrap:hover::after{
        border: 2px solid var(--color-primary-3);
    }
}

.details-thumb{
    img{
        width: 100%;
    }
}